import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
// import Grid from '@material-ui/core/Grid';
// import IntlMessages from "../../../../util/IntlMessages";
import { Get1099ComplianceReview, UpdateVendor1099, MarkVendorReview, GetReviewVendorHistory, InsertUpdateComplianceReviewNotes, GetComplianceReviewNotes, Update1099ReviewExcludeTotalStatus } from "../../../../appRedux/actions/LedgerAction";
import { vendorTypes, SetVendorType, doNotIssueDeopdownValues } from "../../../../appRedux/actions/vendorAction";
// import { tsConstructorType } from "@babel/types";
import { makeLinkForQuickbooks, numberWithCommas, Getsum, MaskTaxIdentifier, ReportType, LinkType } from "../../../common/commonServices";
import { UpdateVendorDoNotIssue1099 } from "../../../../appRedux/actions/ReportDetailAction";
import CircularProgress from '../../../../components/CircularProgress';
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
// import { stat } from "fs";
// import Select from '@material-ui/core/Select';
import Textarea from 'react-expanding-textarea';
import { Note } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import VendorsLogHistory from '../../../common/vendorsHistoryModel'
import { dropdownValueMapping } from '../../../common/1099ReportingDataMapping'
class ReviewReportPage extends Component {

  constructor(props) {

    super(props)
    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(), 0, 1);

    if (eDate.getMonth() <= 2) {
      sDate = new Date(sDate.getFullYear() - 1, 0, 1);
      eDate = new Date(eDate.getFullYear() - 1, 11, 31);
    }
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 25; year--) {
      if (year >= 2020) {
        years.push(year);
      }
    }
    this.years = years

    this.state = {
      companyId: 0,
      loading: false,
      sDate: sDate,
      eDate: eDate,
      ReportData: [],
      editedData: null,
      vendorTypeData: [],
      doNotIssueDropdownOptions: [],
      selectedListId: "",
      selectedTypeId: 0,
      IsReviewProcessing: false,
      ShowHistory: false,
      editNotes: false,
      Notes: "",
      prevNotes: "",
      editIndex: -1,
      exclude1099Note: "This transaction is not included in the 1099 total.",
      isExcludeFrom1099ReviewTotal: null,
      editDoNotIssueIndex: -1,
      editData: null,
      prevDoNotIssueText: "",
      ShowHistoryModal: false
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      companyId: user.companyId,
      loading: true
    }, () => {
      this.props.GetReviewVendorHistory(user.companyId);
      this.props.vendorTypes();
      this.props.doNotIssueDeopdownValues()
      this.GetReportData();
    });
  }

  GetReportData = () => {
    let body = {
      "companyID": this.state.companyId,
      "fromDate": moment(this.state.sDate).format("MM/DD/YYYY"),
      "toDate": moment(this.state.eDate).format("MM/DD/YYYY"),
      "needUpdatQBORecords": false,
      "reportType": ReportType.Vendor1099ComplianceReview
    }
    this.props.Get1099ComplianceReview(body);
  }

  componentWillReceiveProps(nextprops) {

    if (nextprops.ComplianceReviewData !== undefined && nextprops.ComplianceReviewData !== this.props.ComplianceReviewData) {
      if (nextprops.ComplianceReviewData.transactionStatus === 0) {
        if (nextprops.ComplianceReviewData.returnObject != null) {
          this.setState({ loading: false, ReportData: nextprops.ComplianceReviewData.returnObject })
          this.props.GetComplianceReviewNotes(this.state.companyId, ReportType.Vendor1099ComplianceReview);
        }
        else {
          this.setState({ loading: false }, () => { toast.error(` No Data Found!!!`); });
          this.props.GetComplianceReviewNotes(this.state.companyId, ReportType.Vendor1099ComplianceReview);
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.ComplianceReviewData.resultMsg}`); });
        this.props.GetComplianceReviewNotes(this.state.companyId, ReportType.Vendor1099ComplianceReview);
      }
    }



    if (nextprops.HistoryData !== undefined && nextprops.HistoryData != null && nextprops.HistoryData.returnObject != this.props.HistoryData) {
      if (nextprops.HistoryData.transactionStatus === 0) {
        this.setState({ loading: false, ShowHistory: true, HistoryData: nextprops.HistoryData.returnObject });
      }
    }



    if (nextprops.GetComplianceReviewNotesData !== undefined && nextprops.GetComplianceReviewNotesData != this.props.GetComplianceReviewNotesData) {

      if (nextprops.GetComplianceReviewNotesData.transactionStatus === 0) {
        if (nextprops.GetComplianceReviewNotesData.returnObject != null) {
          this.setState({ loading: false, Notes: nextprops.GetComplianceReviewNotesData.returnObject.notes });
        } else {
          this.setState({ loading: false, Notes: "" });
        }
      }
    }


    if (nextprops.ComplianceReviewNotesUpdate !== undefined && nextprops.ComplianceReviewNotesUpdate !== this.props.ComplianceReviewNotesUpdate) {

      if (nextprops.ComplianceReviewNotesUpdate.transactionStatus === 0) {
        this.setState({ editNotes: false, Notes: this.state.prevNotes }, () => { toast.success(`${nextprops.ComplianceReviewNotesUpdate.resultMsg}`); });
        this.props.GetComplianceReviewNotes(this.state.companyId, ReportType.Vendor1099ComplianceReview);
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.ComplianceReviewNotesUpdate.resultMsg}`); });
      }
    }

    if (nextprops.CommonUpdate !== undefined && nextprops.CommonUpdate !== this.props.CommonUpdate) {
      if (nextprops.CommonUpdate.transactionStatus === 0) {
        let data = this.state.ReportData;
        if (this.state.IsReviewProcessing == false) {
          let obj = this.state.editedData;
          data.map((item, index) => {
            if (item.listID === obj.listID) {
              item.vendor1099 = obj.vendor1099
            }
          })

          this.setState({ editedData: null, IsReviewProcessing: false, reportData: data }, () => {
            toast.success("Request Process Successfully");
            // this.GetReportData();
          });
        }
        else {
          this.setState({ IsReviewProcessing: false }, () => {
            toast.success("Reviewed Successfully");
            this.props.GetReviewVendorHistory(this.state.companyId);
          });
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextprops.CommonUpdate.resultMsg}`); });
      }

    }
    if (nextprops.vendorTypeData !== undefined && nextprops.vendorTypeData !== this.props.vendorTypeData) {
      this.setState({ loading: false, vendorTypeData: nextprops.vendorTypeData });
    }

    if (nextprops.doNotIssueDropdownOptions !== undefined && nextprops.doNotIssueDropdownOptions !== this.props.doNotIssueDropdownOptions) {
      this.setState({ loading: false, doNotIssueDropdownOptions: nextprops.doNotIssueDropdownOptions.returnObject });
    }
    if (nextprops.wagesUpdate !== undefined && nextprops.wagesUpdate !== this.props.wagesUpdate) {
      this.setState({ loading: false }, () => {

        if (nextprops.wagesUpdate.transactionStatus === 0) {
          const data = [...this.state.ReportData];
          let selectedid = this.state.selectedListId;
          data.forEach(element => {
            if (element.listID === selectedid) {
              element.vendorTypeId = parseInt(this.state.selectedTypeId);
            }
          });
          this.setState({ ReportData: data, selectedListId: "", selectedTypeId: 0 }, () => {
            toast.success("Updated successfully.");
            // this.GetReportData();
          });

        }
        else {
          this.setState({ loading: false }, () => { toast.error("failed to update.") });
        }
      });
    }

    if (nextprops.LEDGER_UPDATE != undefined && nextprops.LEDGER_UPDATE !== this.props.LEDGER_UPDATE) {
      if (nextprops.LEDGER_UPDATE.transactionStatus === 0) {

        this.setState({
          loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
        }, () => {
          toast.success("Record updated successfully");
          // this.GetReportData();
        });

      }
      else {
        this.setState({
          loading: false, editIndex: -1, isExcludeFrom1099ReviewTotal: null
        }, () => {
          toast.error(`${nextprops.LEDGER_UPDATE.resultMsg}`);
        });
      }
    }

    if (nextprops.UpdateDescription !== this.props.UpdateDescription) {
      if (nextprops.UpdateDescription.transactionStatus === 0) {
        this.setState({
          // loading: true
          loading: false,
          editDoNotIssueIndex: -1, editData: null, prevDoNotIssueText: ""
        }, () => {
          toast.success(`${nextprops.UpdateDescription.resultMsg}`);
          // this.GetReportData();
        });
      }
      else {
        this.setState({
          loading: false, editDoNotIssueIndex: -1, editData: null, prevDoNotIssueText: ""
        }, () => {
          toast.error(`${nextprops.UpdateDescription.resultMsg}`);
        });
      }
    }
  }
  CloseHistory = () => {
    this.setState({ ShowHistoryModal: false });
  }
  getHistory = (item) => {
    this.setState({ vendorID: item.vendorID, ShowHistoryModal: true })


  }
  onMarkReviewd = () => {
    this.setState({
      IsReviewProcessing: true,
      //  loading: true
      loading: false
    }, () => {
      this.props.MarkVendorReview(this.state.companyId);
    });
  }

  YearChange = (e) => {

    this.setState({ eDate: new Date(e.target.value, 11, 31), sDate: new Date(e.target.value, 0, 1) }, () => {

      this.GetReportData();
    });
  }

  onLinkClick = (rowData) => {
    let origin = makeLinkForQuickbooks();
    window.open(origin + rowData.transactionLink);
  }

  onCheckboxClick = (e, item) => {
    let body = {
      "companyID": this.state.companyId,
      "vendor1099": e.target.checked,
      "listID": item.listID
    }

    this.setState({
      editedData: body,
      loading: false
    }, () => {
      this.props.UpdateVendor1099(body);
    })
  }

  ChangeType = (e, listId) => {

    var obj =
    {
      "listId": listId,
      "vendorTypeId": e.target.value === "" ? null : e.target.value,
      "companyId": this.state.companyId
    }

    this.setState({
      // loading: false
      loading: false, selectedListId: listId, selectedTypeId: e.target.value
    }, () => {
      this.props.SetVendorType(obj)
    });

  }

  HandleClose = () => {
    this.setState({ ShowHistory: false });
  }

  onClickCloseButtonDate = (e) => {
    this.setState({ editNotes: false, Notes: this.state.prevNotes });
  }

  handalTextArea = (e) => {
    this.setState({ Notes: e.target.value });
  }

  editNotesData = () => {
    this.setState({ editNotes: true, prevNotes: this.state.Notes });
  }


  UpdateNotes = () => {

    var obj =
    {
      "companyID": this.state.companyId,
      "reportType": ReportType.Vendor1099ComplianceReview,
      "notes": this.state.Notes
    }

    this.setState({
      // loading: true
      loading: false
    }, () => {
      this.props.InsertUpdateComplianceReviewNotes(obj)
    });

  }

  onRowSave = (index, rowData, vendorData) => {

    if (this.state.isExcludeFrom1099ReviewTotal) {
      if (vendorData.vendor1099 != true) {
        toast.error("Not able to exclude transactions because the vendor is not selected as a 1099 vendor.");
        return false;
      }
    }

    let body =
    {
      "id": rowData.id,
      "isExcludeFrom1099Total": this.state.isExcludeFrom1099ReviewTotal,
      "companyID": this.state.companyId,
      "reportType": ReportType.Vendor1099ComplianceReview
    }

    this.setState({
      // loading: true
      loading: false
    }, () => {
      this.props.Update1099ReviewExcludeTotalStatus(body);
    });
  }

  onRowCancel = (rowIndex, rowData) => {
    //rowData.memoOrDescription = null;
    this.setState({ isExcludeFrom1099ReviewTotal: null, editIndex: -1 });
  }

  onRowEdit = (j, rowData) => {
    this.setState({ isExcludeFrom1099ReviewTotal: rowData.isExcludeFrom1099Total, editIndex: j });
  };

  onChange1099ExcludeCheckbox = (e) => {
    this.setState({ isExcludeFrom1099ReviewTotal: e.target.checked });
  }

  editDoNot1099Data = (index, rowData) => {
    let data = [...this.state.ReportData];
    if (this.state.editData != null && this.state.editDoNotIssueIndex != -1) {
      data[this.state.editDoNotIssueIndex].doNotIssue1099 = this.state.prevDoNotIssueText;
    }
    this.setState({ ReportData: data, editDoNotIssueIndex: index, editData: rowData, prevDoNotIssueText: rowData.doNotIssue1099 });
  }

  handleFormChange = (e, index) => {

    var data = this.state.editData;
    data.doNotIssue1099 = e.target.value
    this.setState({ editData: data });
  }

  onClickCloseDoNot1099 = (e, index) => {
    let data = [...this.state.ReportData];
    data[index].doNotIssue1099 = this.state.prevDoNotIssueText;
    this.setState({ ReportData: data, editData: null, editDoNotIssueIndex: -1 });
  }

  saveDoNotIssue1099 = (item) => {
    let editedData = this.state.editData;
    const totalpayments = Getsum(item.reportData.filter(a => a.transactionType !== "Bill"), "amount")
    let flag = false;
    if (Getsum(item.reportData.filter(a => a.transactionType !== "Bill"), "amount") >= 600 && this.state.editData.vendor1099 && (this.state.editData.doNotIssue1099 === "6"
      || this.state.editData.doNotIssue1099 === "7" || this.state.editData.doNotIssue1099 === "8")) {
      flag = true
    }
    // if (editedData.doNotIssue1099 == null || editedData.doNotIssue1099.trim().replace(/\s/g, '') === "") {
    //     toast.error("Please select Do Not Issue 1099 value.");
    //     return false;
    // }

    let body = {
      "companyID": this.state.companyId,
      "id": editedData.vendorID,
      "doNotIssue1099": editedData.doNotIssue1099,
      "reportType": ReportType.Vendor1099PendingClientApproval,
      // "listID": item.listID,
      "isUpdateVendorEINAndAddress": flag
    }

    this.setState({
      // loading: true
      loading: false
    }, () => {
      this.props.UpdateVendorDoNotIssue1099(body);
    })
  }


  render() {
    return (
      <div className="dashboardPage">
        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | 1099 Compliance Review</h2>
        </div>
        {this.state.ShowHistory && this.state.HistoryData != null && this.state.HistoryData.length > 0 &&
          <React.Fragment>
            <Dialog
              className="comment-Top-align"
              maxWidth="sm"
              fullWidth={true}
              open={true}>
              <React.Fragment>
                <div className="Comments modal-header"><span className="log-history-title float-left">Reviewed History </span>
                  <Button className="jr-btn close-btn float-right" onClick={this.HandleClose}><ClearIcon /></Button>
                </div>
                <DialogContent>
                  {this.state.HistoryData.length == 0 ?
                    <div className="history-box">
                      <p className="history-item-text">No History Found</p>
                    </div>
                    :
                    <React.Fragment>
                      {this.state.HistoryData.map((item, i) => (
                        <div className="history-box">
                          {/* <p className="history-item-text">
                           
                          </p>
                           */}
                          <p className="history-date-text"> <strong style={{ color: "black" }}>  Last Reviewed  </strong> By <strong> {item.userName}</strong> <strong> on </strong>{item.stCreatedDate}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                </DialogContent>
              </React.Fragment>
            </Dialog>
          </React.Fragment>

        }
        <div className="padding-box app-wrapper compliance-review">
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col-md-12">
              <div className="left-box">

                {!this.state.editNotes ?
                  <div className="col-md-12">
                    <h5>Notes: {this.state.Notes}
                      <span className="" onClick={(e) => this.editNotesData()}>
                        <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer"></i>
                      </span>
                    </h5>
                  </div>
                  :
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-1" style={{ marginTop: "5px" }}>
                        <h5>Notes:</h5>
                      </div>
                      <div className="col-md-7" style={{ marginLeft: "-60px" }}>
                        <Textarea className="placheholder-color"
                          value={this.state.Notes} name="Notes"
                          style={{ 'lineHeight': '20px', width: '100%', border: 'none', resize: 'none', padding: '6px', paddingLeft: '10px', borderRadius: '3px', border: '1px solid #ccc', overflow: 'hidden' }}
                          resize={"false"} placeholder=""
                          onChange={(e) => this.handalTextArea(e)} />
                      </div>
                      <div className="col-md-2" style={{ marginLeft: "-10px", marginTop: "4px" }}>
                        {/* <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.UpdateNotes()} >Save</Button> */}
                        <Button className="jr-btn close-btn" onClick={(e) => this.UpdateNotes(e)}><CheckIcon /></Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseButtonDate(e)}><ClearIcon /></Button>

                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-12">
              <div className="float-right">
                {this.state.loading === false ?
                  <> <span>Select Year:</span>
                    <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{ marginRight: "10px", height: '40px' }}>
                      {/* <option value=""></option> */}
                      {this.years.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select></>
                  : ""}
                {this.state.loading === false ?
                  <Button variant="raised" className="graycolor jr-btn bg-teal text-white refresh-btn" onClick={() => this.onMarkReviewd()} >Mark as Reviewed</Button>
                  : ""}
              </div>
            </div>
          </div>
          {this.state.loading ? <CircularProgress></CircularProgress>
            :
            <React.Fragment>
              {/* {JSON.stringify(this.state)} */}
              {this.state.ShowHistoryModal ? <VendorsLogHistory Module="Vendors History"
                CompanyId={this.state.companyId}
                VendorId={this.state.vendorID}
                CloseHistory={this.CloseHistory} /> : ''}

              {this.state.ReportData != null && this.state.ReportData.map((item, i) => (
                <div className="" >
                  <div className="complince-review-box">
                    <div className="left-box">
                      <h5>
                        {item.vendor +
                          (item.vendorID === item.employeeId && item.employeeId !== 0 && item.customerId === 0
                            ? " - (Employee)"
                            : item.vendorID === item.customerId && item.customerId !== 0 && item.employeeId === 0
                              ? " - (Customer)"
                              : "")}
                      </h5>
                    </div>
                    <div className="centar-box" style={{ padding: "0 15px !important" }}>
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div style={{ maxWidth: "280px", marginRight: "40px" }}>
                          <strong class=""> Tax ID Number: {MaskTaxIdentifier(item.taxIdentifier)}</strong>
                        </div>
                        <div className="d-flex" style={{ alignItems: "center" }}>

                          {this.state.editDoNotIssueIndex == i ?
                            <React.Fragment>
                              <strong>W-9/1099 Reporting Issues: </strong>
                              <select className="select-box arrow-box" name="doNotIssue1099" onChange={(e) => this.handleFormChange(e, i)} value={this.state.editData.doNotIssue1099} style={{ marginRight: "10px" }}>
                                <option value=""></option>
                                {/* <option value="1">Our payroll service provider will issue 1099s</option>
                                      <option value="2">The payments to this vendor is not for compensation</option>       
                                      <option value="3">We do not want to report compensation to this vendor</option> 
                                      <option value="4">Vendor Processes Payments Electronically</option>   
                                      <option value="5">We have recently requested a W-9 from vendor</option>                                                    
                                      <option value="6">This vendor refuses to complete W-9</option>                                                    
                                      <option value="7">We requested a W-9 from the vendor at least twice more than 30 days ago and we have not received a response from the vendor</option>                                                    
                                      <option value="8">We have no contact information for this vendor</option>                                                                                                     */}
                                {this.state.doNotIssueDropdownOptions.map(type => (
                                  <option value={type.id}>
                                    {type.description}
                                  </option>
                                ))}
                              </select>
                              <Button className="jr-btn close-btn" onClick={(e) => this.saveDoNotIssue1099(item)}><CheckIcon /></Button>&nbsp;&nbsp;
                              <Button className="jr-btn close-btn" onClick={(e) => this.onClickCloseDoNot1099(e, i)}><ClearIcon /></Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {item.doNotIssue1099 ?
                                <strong style={{ color: "red" }}>
                                  W-9/1099 Reporting Issues.
                                  {dropdownValueMapping[`${item.doNotIssue1099}`]}.</strong>
                                :
                                <strong> W-9/1099 Reporting Issues: </strong>
                              }
                              <span className="" onClick={(e) => this.editDoNot1099Data(i, item)}>
                                <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer" style={{ marginLeft: "10px !important" }}></i>
                              </span>
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                    <div className='d-flex' style={{ marginLeft: 20 }} >
                      <Tooltip title="W-9/1099 Reporting Issues history">
                        <Button className="jr-btn close-btn" onClick={(e) => { this.getHistory(item) }}><HistoryIcon /></Button>

                      </Tooltip></div>
                    <div className="right-box">
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <div className='d-flex' >
                          <span style={{ margin: "5px" }}><strong>Type:</strong> </span>
                          <select className="select-box" name={item.listID} onChange={(e) => this.ChangeType(e, item.listID)} style={{ background: "white", marginTop: "-2px", marginRight: "10px" }} >
                            <option value="" >Select</option>
                            {this.state.vendorTypeData.map(type => (
                              <option value={type.vendorTypeId} selected={type.vendorTypeId === item.vendorTypeId ? "selected" : ""}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex">
                          <p>1099 Vendor </p>
                          <label className="container101">
                            {/* {item.doNotIssue1099 ?
                              <React.Fragment>
                                {item.vendor1099 ?
                                  <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onCheckboxClick(e, item)} disabled/>
                                  :
                                  <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onCheckboxClick(e, item)} disabled/>
                                }
                                <span className="checkmark101 green_CheckMark" style={{background:"lightgray"}}></span>
                              </React.Fragment>
                              : */}
                            <React.Fragment>
                              {item.vendor1099 ?
                                <input className="second-check-box" name={item.listID} checked="checked" type="checkbox" onChange={(e) => this.onCheckboxClick(e, item)} />
                                :
                                <input className="second-check-box" type="checkbox" name={item.listID} onChange={(e) => this.onCheckboxClick(e, item)} />
                              }
                              <span className="checkmark101 green_CheckMark"></span>
                            </React.Fragment>
                            {/* } */}

                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* table-responsive-material */}
                    <div className=""></div>
                    <table className="table" style={{ tableLayout: "fixed", width: "100%" }}>
                      <thead>
                        <tr className="header-bg-color">
                          <th>
                            Date
                          </th>
                          <th>
                            Type
                          </th>
                          <th>
                            Number
                          </th>
                          <th>
                            Memo/Description
                          </th>
                          <th style={{ width: "18%" }}>
                            Account
                          </th>
                          <th className="text-center"> Amount   </th>
                          <th className="text-center">
                            Exclude from 1099 Total
                          </th>
                          <th className="text-center"><img src="/vendors/Link-arrow_header.png" alt="link" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.reportData != null & item.reportData.length > 0 ?
                          <React.Fragment key={i}>

                            <tr>
                              <td colSpan="8" align="left" > <b>Bills</b> </td>
                            </tr>

                            {item.reportData != null && item.reportData.filter(a => a.transactionType === "Bill").map((data, index) => (
                              <React.Fragment key={index}>
                                {
                                  data.isExcludeFrom1099Total ?
                                    <tr onClick={(e) => this.onRowEdit(data.id, data)}>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}> {data.date}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.transactionType}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.num}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.memoOrDescription}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}> {data.accountValue}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center"> <i class="zmdi zmdi-close zmdi-hc-2x" style={{ color: "red" }}></i></td>
                                      <td style={{ width: "12%", backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                    </tr>
                                    :
                                    <tr onClick={(e) => this.onRowEdit(data.id, data)}>
                                      <td> {data.date}</td>
                                      <td>{data.transactionType}</td>
                                      <td >{data.num}</td>
                                      <td>{data.memoOrDescription}</td>
                                      <td> {data.accountValue}</td>
                                      <td className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                      <td></td>
                                      <td style={{ width: "12%" }} className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                    </tr>
                                }
                                {this.state.editIndex === data.id && //item.vendor1099 &&
                                  <React.Fragment>
                                    <tr className="editRowColor">
                                      <td colSpan={6}></td>
                                      <td className="text-center">
                                        <div className="chiller_cb ">
                                          <input id="isExcludeFrom1099Total" name="isExcludeFrom1099Total" type="checkbox" checked={this.state.isExcludeFrom1099ReviewTotal} onChange={(e) => this.onChange1099ExcludeCheckbox(e)} />
                                          <label for="isExcludeFrom1099Total">
                                            <p className="checkbox-title" style={{ fontSize: "14px" }}>Exclude From 1099 Total</p>
                                          </label>
                                          <span></span>
                                        </div>
                                      </td>
                                      <td className="text-center" style={{ width: "12%" }}>
                                        <div className="col-md-12">
                                          <Button style={{ marginRight: "10px" }} variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(data.id, data, item)}>SAVE</Button>
                                          <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(data.id, data)}>CANCEL</Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            ))}
                            {item.reportData.filter(a => a.transactionType === "Bill" && a.isExcludeFrom1099Total == true).length > 0 &&
                              <React.Fragment>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td><h2 className="total-payments-text">Adjusted Total 1099 Bills</h2></td>
                                  <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.transactionType === "Bill" && a.isExcludeFrom1099Total != true), "amount")} </h1></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </React.Fragment>
                            }
                            <tr>
                              <td colSpan="4"></td>
                              <td><h2 className="total-payments-text">Total Bills</h2></td>
                              <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.transactionType === "Bill"), "amount")} </h1></td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <td colSpan="8" align="left" > <b>Payments </b> </td>
                            </tr>
                            {item.reportData != null && item.reportData.filter(a => a.transactionType !== "Bill").map((data, index) => (
                              <React.Fragment key={index}>
                                {
                                  data.isExcludeFrom1099Total ?
                                    <tr onClick={(e) => this.onRowEdit(data.id, data)}>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}> {data.date}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.transactionType}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.num}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}>{data.memoOrDescription}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note}> {data.accountValue}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                      <td style={{ backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center"> <i class="zmdi zmdi-close zmdi-hc-2x" style={{ color: "red" }}></i></td>
                                      <td style={{ width: "12%", backgroundColor: "lightgrey" }} title={this.state.exclude1099Note} className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                    </tr>
                                    :
                                    <tr onClick={(e) => this.onRowEdit(data.id, data)}>
                                      <td> {data.date}</td>
                                      <td>{data.transactionType}</td>
                                      <td >{data.num}</td>
                                      <td>{data.memoOrDescription}</td>
                                      <td> {data.accountValue}</td>
                                      <td className="text-center pr-2">${numberWithCommas(data.amount)}</td>
                                      <td></td>
                                      <td style={{ width: "12%" }} className="text-center"><img alt="link" src="/vendors/grrenLink.png" onClick={(e) => this.onLinkClick(data)}></img></td>
                                    </tr>
                                }
                                {this.state.editIndex === data.id && //item.vendor1099 &&
                                  <React.Fragment>
                                    <tr className="editRowColor">
                                      <td colSpan={6}></td>
                                      <td className="text-center">
                                        <div className="chiller_cb ">
                                          <input id="isExcludeFrom1099Total" name="isExcludeFrom1099Total" type="checkbox" checked={this.state.isExcludeFrom1099ReviewTotal} onChange={(e) => this.onChange1099ExcludeCheckbox(e)} />
                                          <label for="isExcludeFrom1099Total">
                                            <p className="checkbox-title" style={{ fontSize: "14px" }}>Exclude From 1099 Total</p>
                                          </label>
                                          <span></span>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <div className="col-md-12">
                                          <Button variant="raised" className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(data.id, data, item)}>SAVE</Button>
                                          <Button variant="raised" className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(data.id, data)}>CANCEL</Button>
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            ))}
                            {item.reportData.filter(a => a.transactionType !== "Bill" && a.isExcludeFrom1099Total == true).length > 0 &&
                              <React.Fragment>
                                <tr>
                                  <td colSpan="4"></td>
                                  <td><h2 className="total-payments-text">Adjusted Total 1099 Payments</h2></td>
                                  <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.transactionType !== "Bill" && a.isExcludeFrom1099Total != true), "amount")} </h1></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </React.Fragment>
                            }
                            <tr>
                              <td colSpan="4"></td>
                              <td><h2 className="total-payments-text">Total Payments</h2></td>
                              <td className="text-center pr-2"><h1 className="total-text"> ${Getsum(item.reportData.filter(a => a.transactionType !== "Bill"), "amount")} </h1></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </React.Fragment>
                          :
                          <tr>
                            <td className="text-center" colSpan="8">
                              No Data Found!!!
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ComplianceReviewData: state.ledgers.ComplianceReviewData,
    CommonUpdate: state.ledgers.CommonUpdate,
    vendorTypeData: state.vendors.vendorTypes,
    doNotIssueDropdownOptions: state.vendors.doNotIssueDeopdownValues,
    wagesUpdate: state.vendors.wagesUpdate,
    HistoryData: state.ledgers.HistoryData,
    ComplianceReviewNotesUpdate: state.ledgers.ComplianceReviewNotesUpdate,
    GetComplianceReviewNotesData: state.ledgers.GetComplianceReviewNotesData,
    LEDGER_UPDATE: state.ledgers.LEDGER_UPDATE,
    UpdateDescription: state.ReportData.UpdateDescription,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Get1099ComplianceReview,
    vendorTypes,
    doNotIssueDeopdownValues,
    UpdateVendor1099,
    SetVendorType,
    MarkVendorReview,
    GetReviewVendorHistory,
    InsertUpdateComplianceReviewNotes,
    GetComplianceReviewNotes,
    Update1099ReviewExcludeTotalStatus,
    UpdateVendorDoNotIssue1099
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewReportPage);
